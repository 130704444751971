<template>
    <app-transition>
        <Dialog
            :only-text="true"
            @close="handleClose"
            class="add-account-dialog" >
            <v-row text-center="" class="mt-4">
                <template>
                    <v-col cols="12">
                        <v-btn block class="btn-accent" @click="$emit('signup')">{{ $t('dialogs.switch_account.create_account') }}</v-btn>
                    </v-col>
                    <v-col cols="12">
                        <v-btn block class="grey darken-3" @click="$emit('login')">{{ $t('dialogs.switch_account.login_to_account') }}</v-btn>
                    </v-col>
                </template>
            </v-row>
        </Dialog>
    </app-transition>
</template>


<script>
import Dialog from "@/components/app/form/Dialog";

export default {
    name: "AddAccountDialog",
    methods: {
        handleClose() {
            this.$emit('close');
        },
        handleConfirm(){
            this.$emit('confirm');
        },
    },
    props: {
        currentUser: {
            type: String, 
            required: false,
        },
        linkedUsers: {
            type: Array,
            default: () => [],
            required: false,
        },
    },
    components: {
        Dialog,
    }
}
</script>

<style lang="scss">
@import '@/sass/modules/_variables';

.add-account-dialog {
    .DialogContent {
        width: 90% !important;
        background: black;
        border: 1px solid #2e2e2e;
        box-shadow: 0 0 40px 5px rgba(255, 255, 255, 0.15);
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 24px !important;
    }
}
</style>
